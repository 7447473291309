import React, { useState, useEffect } from 'react';
import Container from '../components/container';
import '../styles/main.scss';
import '../styles/still.scss';
import '../styles/still2.scss';
import Layout from '../components/layout';
import StillHero from '../components/stillHero.js';
import StillCreator from '../components/stillCreator';
import { Helmet } from 'react-helmet';
import img1 from '../images/1.jpg';
import img2 from '../images/2.jpg';
import img3 from '../images/3.jpg';
import img4 from '../images/4.jpg';
import img5 from '../images/5.jpg';
import img6 from '../images/6.jpg';
import img7 from '../images/7.jpg';
import img8 from '../images/8.jpg';
import img9 from '../images/9.jpg';
import img10 from '../images/10.jpg';
import img11 from '../images/11.jpg';
import img12 from '../images/12.jpg';
import img13 from '../images/13.jpg';
import img14 from '../images/14.jpg';
import img15 from '../images/15.jpg';
import img16 from '../images/16.jpg';
import img17 from '../images/17.jpg';
import img18 from '../images/18.jpg';

const Wearestill = () => {
  const imagesToRender = [
    [{ image: img2 }],
    [
      { image: img3, maxWidth: '576px', width: '40%' },
      { image: img4, maxWidth: '849px', width: '55%' },
    ],
    [{ image: img5 }],
    [{ image: img6 }],
    [{ image: img7 }],
    [
      { image: img8, maxWidth: '579px', width: '40%' },
      { image: img9, maxWidth: '837px', width: '55%' },
    ],
    [{ image: img10 }],
    [{ image: img18 }],
    [{ image: img11 }],
    [{ image: img12 }],
    [
      { image: img13, maxWidth: '841px', width: '50%' },
      { image: img14, maxWidth: '560px', width: '40%' },
    ],
    [{ image: img15 }],
    [{ image: img16 }],
    [{ image: img17 }],
  ];

  return (
    <>
      <Helmet>
        <title>G58 - We are still</title>
      </Helmet>
      <Layout>
        <Container>
          <div
            style={{
              maxWidth: '1500px',
              marginRight: 'auto',
              marginLeft: 'auto',
            }}>
            <StillHero />
            <StillCreator imgs={imagesToRender} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Wearestill;
