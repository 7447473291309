import React from "react";
import stillHero from "../images/still-hero.jpg";
import "../styles/still.scss";

const StillHero = () => {
  return (
    <>
      <div className="still__hero__wrapper">
        <div className="still__hero__right">
          <h3> We are still </h3>
        </div>
        <div className="still__hero__img">
          <img src={stillHero} />
        </div>
      </div>
    </>
  );
};

export default StillHero;
