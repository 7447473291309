import React from "react";

const StillCreator = ({ imgs, align, images }) => {
  return (
    <div className="still__images-container">
      {imgs.map((item) => {
        return (
          <div className={`still__images${item.length > 1 ? "-2" : ""}`}>
            {item.map(({ image, maxWidth, width }) => (
              <img src={image} />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default StillCreator;
